.data-table {
  button.disabled {
    background-color: grey !important;
    border-color: grey !important;
  }
}

.react-bs-table-container {
  .react-bs-table table th,
  .react-bs-table table td {
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
  }
}

table td {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding: 2px 6px !important;
  p {
    margin: 0 !important;
  }
}

.sticky-table table th:first-child,
.sticky-table table td:first-child {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  left: 0px;
  // box-shadow: 2px 0px 6px -3px;
  // border: none !important;
}

.sticky-table table th:nth-child(2),
.sticky-table table td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  left: 39px;
  // box-shadow: 2px 0px 6px -3px;
  // border: none !important;
}

.sticky-table table th.disableScroll,
.sticky-table table td.disableScroll {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}


.filter-tools {
  display: flex;
  flex-flow: wrap;
  // margin-bottom: 20px;

  .item {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  button {
    height: 37px;
  }
}

.crud-buttons {
  margin: 0;
  padding: 0;
  text-align: right;

  // button {
  //   margin-bottom: 10px;
  // }

  button:not(:last-child) {
    margin-right: 5px;
  }
}

.table-modal {
  // max-width: 1000px;
  // max-width: 50%;
  display: block;
  height: auto;
  overflow: hidden;
  // max-height: 100%;
  margin: 0 auto;
  // top: 50px;

  // @media (max-width: 1440px) {
  //   max-width: 80%;
  // }
  // @media (max-width: 992px) {
  //   max-width: 90%;
  // }

  .modal-header .close {
    color: #fff;
    text-shadow: unset;
    opacity: 1;
  }

  .modal-content {
    height: 100%;

    form {
      height: 100%;

      .modal-body {
        height: calc(100% - 115px);
        overflow: hidden;
        padding: 0;

        .content {
          height: 100%;
          overflow: auto;
        }
      }
    }
  }
}

.remove-modal {
  .modal-content {
    margin-top: 50%;
  }
}
