/* custom sbadmin2 */
.table {
  color: unset !important;
}

.react-bs-table table td,
.react-bs-table table th {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
}

pre {
  color: unset !important;
}

.figure-caption {
  color: unset !important;
}

.blockquote-footer {
  color: unset !important;
}

caption {
  color: unset !important;
}

body {
  color: unset !important;
}

.form-control {
  color: unset !important;
}

/* end custom sbadmin2 */

.modal-content {
  border: unset !important;
}

.custom-file-label::after {
  content: "Chọn" !important;
  z-index: unset !important;
}

.custom-file-label {
  z-index: unset !important;
}

.align-vertical-center {
  vertical-align: middle !important;
  text-align: center !important;
}

.vertical-center {
  vertical-align: middle !important;
}

.breadcrumb {
  margin-bottom: 0.5rem !important;
}

/* button custom */
/* .btn-link {
  text-align: left !important;
} */

.react-datepicker-popper {
  z-index: 10;
}

/* modal */
@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1300px;
  }
}

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1400px;
  }
}