// import variables
@import "./variable.scss";
// Sidebar
$sidebar-link-padding: 7.5px 7.5px;
$sidebar-icon-size: 18px;
$sidebar-first-level-padding: 0 0 0 25px;
$sidebar-first-level-link-padding: 0 0;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0 0 0px 20px;

/*******************
Main sidebar
******************/

.left-sidebar {
  position: fixed;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;
  z-index: 10;
  padding-top: $topbar-height;
  background: $sidebar;
  box-shadow: $shadow;
  transition: 0.2s ease-in;
  font-size: $sidebar-fontsize;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  box-shadow: 1px 5px 5px 0px;
  margin-bottom: 25px;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #0000004d;
    background-color: #f5f5f5;
  }
}

.sidebar-nav {
  ul {
    .sidebar-item {
      width: $sidebar-width-full;

      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        align-items: center;
        line-height: 25px;
        border-left: solid 3px white;
        cursor: pointer;

        .side-badge.badge {
          position: absolute;
          right: 32px;
          top: 17px;
          padding: 3px 10px;
          border-radius: 4px;
        }

        i {
          font-style: normal;
          width: 30px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-text;
          display: inline-block;
          text-align: center;
          margin-right: 3px;
        }

        &.active {
          font-weight: 600;
        }

        &.active,
        &:hover {
          color: #2b7dbc;

          * {
            color: #2b7dbc;
          }
        }
      }

      &.selected > .sidebar-link,
      &:hover > .sidebar-link {
        color: #2b7dbc;
        border-left: 3px solid #2b7dbc;
        text-decoration: none;

        * {
          color: #2b7dbc;
        }
      }

      /*******************
           First level
           ******************/
      .first-level {
        background: #fff;
        padding: $sidebar-first-level-padding;

        .sidebar-item {
          width: 100%;
          border-left: dotted 2px #2b7dbc;
          &:hover {
            .sidebar-link {
              border-left: unset;
            }
          }
          .sidebar-link {
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f068";
              color: #2b7dbc;
              font-size: 8px;
              margin-left: -1px;
              margin-right: 5px;
            }
            &:hover {
              &::before {
                content: "\f0da ";
                color: #2b7dbc;
                margin-left: -1px;
                font-size: 20px;
              }
            }
            &.active {
              border-left: unset;
              &::before {
                color: #c86139;
                content: "\f0da ";
                margin-left: 2px;
                font-size: 20px;
              }
            }
          }
        }

        .sidebar-item.active .sidebar-link {
          opacity: 1;
        }

        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          overflow: hidden;

          i {
            font-size: $sidebar-first-level-icon-size;
          }
        }
      }

      .second-level .sidebar-item {
        padding: $sidebar-second-level-padding;
      }
    }

    /*******************
         Small cap
         ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $sidebar-link-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      opacity: 0.4;
      text-transform: uppercase;

      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}

/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;
    }
  }
}

.collapse.in {
  display: block;
}

/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 20px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(225deg) translate(0, -50%);
  -ms-transform: rotate(225deg) translate(0, -50%);
  -o-transform: rotate(255deg) translate(0, -50%);
  transform: rotate(225deg) translate(0, -50%);
  border-color: #2b7dbc;
}

.sidebar-nav {
  > ul {
    > li {
      border-style: solid;
      border-width: 1px 0 0;
      border-color: #e5e5e5;
    }
  }
}

.sidebar-nav .user-profile {
  padding: 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);

  > .sidebar-link {
    padding: 12px 8px !important;
  }

  > span {
    img {
      width: 30px;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  ul {
    padding-left: 47px;

    li {
      margin-top: 8px;
    }
  }
}
