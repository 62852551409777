.accordion {
  display: flex;
  flex-direction: column-reverse;
}
.accordion-wrapper {
  max-width: 500px;
  width: 100%;
  position: relative;
  margin: 0 auto;

  & + * {
    margin-bottom: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  background: #347BA3;
  padding: 0.5em;
  border: solid 1px #ccc;
  // border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }

  &:hover,
  &.open {
    // color: black;
  }

  &.open {
    &::after {
      content: "";
      border-top: 0;
      border-bottom: 5px solid;
    }
  }
}

.accordion-content {
  color: #000;

  li.title-content {
    background: #f5f5f5;
    p {
      margin-bottom: 0;
    }
  }
  .list-group-item {
    padding: 0.5em;
  }
}
.hr-dotted {
  border-top: 1px dotted #c9c9c9;
  width: 50%;
  margin: 0 auto;
}
