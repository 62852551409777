#datatable {
  td, th {
    padding: 2px 6px;
  }
}
.diary-select {
  div:nth-child(3) {
    z-index: 3;
  }
}
.diaries-note{
  p {
    margin-bottom: unset;
  }
}
//button back
.cd-top--fade-out {
  opacity: 0.5;
}

.cd-top--is-visible {
  visibility: visible;
  opacity: 1;
}

.backTop i {
  color: #fff;
  background: #FFAE00;
  border: 2px solid #FFAE00;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 10;
}
//
@media (max-width: 420px) {
  .bg-traceability {
    min-height: 300px !important;
    max-height: 300px !important;
  }
}
//
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper {
  width: 100%;
  // padding-top: 40px;
  // padding-bottom: 40px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 200px !important;
  height: 100% !important;
}

.swiper-pagination-fraction {
  color:white;
  background: black;
  width: 50px !important;
}