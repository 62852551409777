.dropdown-control {
  width: 100%;

  >div:first-child() {
    z-index: 4;
  }

  >div:nth-child(2) {
    z-index: 5;
  }
}

.border-red {
  >div {
    border-color: red !important;
  }
}