.snack-bar {
  position: fixed;
  left: 40%;
  top: 40%;
  z-index: 9998;
  min-width: 350px;

  .toast-header {
    color: white;

    button {
      color: white
    }
  }
}

.snack-bar.sb-danger {
  .toast-header {
    background-color: #e74a3b;
  }
}

.snack-bar.sb-success {
  .toast-header {
    background-color: #1cc88a;
  }
}

.snack-bar.sb-info {
  .toast-header {
    background-color: #4e73df;
  }
}