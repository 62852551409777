// App

/*============================================================== 
 For all pages 
 ============================================================== */
 @import './variable.scss';

 #main-wrapper {
	 width: 100%;
	 overflow: hidden;
	 position: relative;
 }
 
 .page-wrapper {
	 background: $body-bg;
	 position: relative;
	 transition: 0.2s ease-in;
	 display: none;
 }
 
 .page-wrapper>.page-content {
	 padding: 30px;
	 min-height: calc(100vh);
 }
 
 .page-breadcrumb {
	 padding: 30px 30px 0px;
 }
 
 /*******************
  Footer
 *******************/
 
 .footer {
	//  padding: 15px 20px;
 }
 
 /*******************
  Stickey inner-left-part
 *******************/
 
 .left-part {
	 width: 200px;
	 position: absolute;
	 border-right: 1px solid $border-color;
 
	 .email-filters,
	 .contact-filters,
	 .chat-users {
		 height: calc(100vh - 242px);
		 overflow: auto;
	 }
 
	 .chat-users {
		 height: calc(100vh - 274px);
	 }
 }
 
 .right-part {
	 width: calc(100% - 200px);
	 height: calc(100vh - 175px);
	 margin-left: 200px;
 
	 &.chat-list {
		 width: calc(100% - 260px);
		 margin-left: 260px;
	 }
 }
 
 .right-left-part {
	 width: 300px;
	 position: absolute;
	 border-right: 1px solid $border-color;
 
	 .email-list,
	 .contact-list {
		 height: calc(100vh - 300px);
		 overflow: auto;
	 }
 
	 .contact-list {
		 height: calc(100vh - 258px);
	 }
 }
 
 .right-right-part {
	 width: calc(100% - 300px);
	 height: calc(100vh - 175px);
	 overflow: auto;
	 margin-left: 300px;
 }
 
 .reverse-mode {
	 .left-part {
		 right: 0;
		 border-left: 1px solid $border-color;
	 }
 
	 .show-left-part {
		 right: auto;
		 left: -41px;
	 }
 
	 .right-part {
		 margin-left: 0px;
		 margin-right: $left-part-width;
	 }
 }
 
 /*******************
  Vertical layout
 *******************/
 
 #main-wrapper[data-layout='vertical'] {
 
	 /*Vertical*/
	 .topbar .top-navbar .navbar-header {
		 //width: $sidebar-width-full;
		 transition: 0.2s ease-in;
	 }
 
	 /*sidebar type*/
	 /* &[data-sidebartype='full'] {
	 .page-wrapper {
	   margin-left: $sidebar-width-full;
	 }
   } */
	 &[data-sidebartype='iconbar'] {
		 .page-wrapper {
			 margin-left: $sidebar-width-iconbar;
		 }
 
		 .left-sidebar,
		 .topbar .top-navbar .navbar-header,
		 .sidebar-nav ul .sidebar-item {
			 width: $sidebar-width-iconbar;
		 }
 
		 .sidebar-nav {
			 ul .sidebar-item {
				 .sidebar-link {
					 display: block;
					 text-align: center;
 
					 i {
						 display: block;
						 width: auto;
					 }
				 }
			 }
		 }
	 }
 
	 &[data-sidebartype='overlay'] {
		 .topbar .top-navbar .navbar-header {
			 width: $sidebar-width-full;
		 }
 
		 .left-sidebar {
			 left: -$sidebar-width-full;
		 }
 
		 &.show-sidebar {
			 .left-sidebar {
				 left: 0;
			 }
		 }
	 }
 
	 /*Sidebar position*/
	 &[data-sidebar-position='fixed'] {
		 .topbar .top-navbar .navbar-header {
			 position: fixed;
			 z-index: 10;
		 }
 
		 .left-sidebar {
			 position: fixed;
		 }
	 }
 
	 /*Header position*/
	 &[data-header-position='fixed'] {
		 .topbar {
			 position: fixed;
			 width: 100%;
		 }
 
		 .page-wrapper {
			 padding-top: $topbar-height;
		 }
	 }
 
	 /*Boxed Layout*/
	 &[data-boxed-layout='boxed'] {
		 max-width: $boxed-width;
		 margin: 0 auto;
		 position: relative;
		 box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
	 }
 
	 &[data-boxed-layout='boxed'][data-header-position='fixed'] {
		 .topbar {
			 max-width: $boxed-width;
		 }
	 }
 }
 
 @media(min-width:1024px) {
	 #main-wrapper[data-layout='vertical'][data-sidebartype='full'] .page-wrapper {
		 margin-left: $sidebar-width-full;
	 }
 }