@import './variable.scss';

label[required] {
  &::after {
    content: ' *';
    color: $danger;
    font-family: SimSun, sans-serif;
    white-space: pre;
  }
}

.error-message {
  display: flex;
  color: $danger;
  margin-top: 5px;

  &::before {
    content: url('../images/error-icon.svg');
    display: block;
    height: auto;
    width: 17px;
    margin-top: 2px;
    margin-right: 5px;
  }
}

.text-danger {
  color: $danger
}

.down-arrow-bf::before {
  content: ' \005E';
}

.z-idx-100 {
  z-index: 100 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.p0 {
  padding: 0 !important;
}

.p10 {
  padding: 10px !important;
}

.p20 {
  padding: 20px !important;
}

.p30 {
  padding: 30px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.addon-group {
  .ant-input-group-addon {
    padding: 0;

    >button {
      border: 0;
      border-radius: 0px;
      width: 100%;
      height: 30px;
    }

    .ant-select {
      padding-left: 10px;
      margin-right: 0px;

      .ant-select-selector {
        border-radius: 0;
        background-color: transparent !important;
      }
    }

  }
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ---------------------Display----------------------
.dp-flex {
  display: flex;
}

//----------------------Scroll-----------------------
.hide-ofl-x {
  overflow-x: hidden !important;
}

//----------------------Font weight-----------------------

.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-9 {
  font-weight: 900;
}

//----------------------Font style-----------------------

.fs-normal {
  font-style: normal;
}

//----------------------Upload Files-----------------------
.form-add {
  &__image {
    height: 70px;
    width: 70px;
  }
  &__button-wrapper {
    position: relative;
    width: 120px;
    text-align: center;
    span.label {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 100%;
      background: #17a2b8;
      cursor: pointer;
      color: #fff;
      padding: 7px 12px;
    }
    &:hover {
      span.label {
        background: #138496;
        transition: 0.25s;
      }
    }
    .upload-box {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 0;
      display: inline-block;
      cursor: pointer;
      z-index: 1;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.swal-text {
  text-align: center;
}