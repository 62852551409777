.react-datepicker__year-wrapper {
  max-width: 220px;
}
.react-datepicker__input-container input {
  width: 100%;
}
.date-picker-wrapper {
  align-items: center;
  > div {
    width: 200px;
  }
  label {
    margin: 0;
  }
}
