#table-diary {
  white-space: nowrap;
  margin: 0;
  border-spacing: 0;
  border-collapse: separate;
  //   border: 1px solid black;
  // table-layout: fixed;

  th {
    border: 1px solid #c9c9c9;
  }

  td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
  }

  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    padding: 3px;
    background: white;
  }

  thead {
    tr.tr-header {
      th:nth-child(1) {
        left: 0;
        z-index: 2;
        min-width: 85px;
      }
      th:nth-child(2) {
        left: 85px;
        z-index: 2;
      }
    }
    tr.tr-subHeader1st {
      th {
        top: 29px;
        
      }
    }
    tr.tr-subHeader2nd {
      th {
        top: 66px;
        
      }
    }
  }

  tbody {
    td:nth-child(1),td:nth-child(2) {
      position: -webkit-sticky;
      position: sticky;
      background: #fff;
      z-index: 1;
     
    }
    td:nth-child(1) {
      left: 0;
    }
    td:nth-child(2) {
      left: 85px;
    }
  }
}
[role="diaries"][aria-labelledby][tabIndex] {
  width: 100%;
  height: 100%;
  max-height: 95vh;
  overflow: auto;
}
[role="diaries"][aria-labelledby][tabIndex]:focus {
  outline: 0;
}
