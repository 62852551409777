.link-more {
    // color: #305c73;
    font-size: 15px;
    padding: 0 7.5px;
    position: relative;
    border: 2px solid transparent;
    outline: 1px solid transparent;
    outline-offset: -3px;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0;
    line-height: 1.3;
    white-space: nowrap;
    transition: all 200ms ease-in-out;
    box-shadow: 0 4px 8px 0 transparent;
    & > span {
        max-width: 100%;
        display: inline-block;
        position: relative;
        left: 0;
        text-overflow: clip;
        overflow: hidden;
        transition: all 200ms ease-in-out;
        vertical-align: top;
        line-height: 18.5px;
    }
    &::after {
        transition: all 200ms ease-in-out;
        font-size: 11px;
        margin-top: 3px;
        margin-left: 3px;
        display: inline-block;
        position: relative;
        right: 0;
        content: ">";
        vertical-align: top;
    }
    
    &:hover {
        span {
            left: -4px;
        }
        &::after {
            font-size: 11px;
            margin-top: 3px;
            margin-left: 3px;
            display: inline-block;
            position: relative;
            right: 0;
            content: ">";
            vertical-align: top;
            right: -4px;
        }
    }
}

.text-dark {
    color: #333333 !important;
}